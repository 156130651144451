import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  EventInformation,
  IraceEvents,
  Navbar,
  Page404,
  PaymentFinish,
} from '.';
import classes from './style';
import { CssBaseline, useMediaQuery } from '@mui/material';
import ReactGA from 'react-ga4';
import { generate } from '../utils/generateToken';

const App = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  // console.log('generateToken : ', generate(59));
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA4_ID);
    // Send pageview with a custom path
  }, []);

  return (
    <div style={classes.root}>
      <CssBaseline />
      <Navbar />
      <main style={isMobile ? classes.contentMobile : classes.content}>
        <div style={classes.toolbar} />
        <Routes>
          <Route path="*" element={<Page404 />} />
          <Route exact path="/payment/finish" element={<PaymentFinish />} />
          <Route exact path="/event/:id" element={<EventInformation />} />
          <Route exact path="/" element={<IraceEvents />} />
        </Routes>
      </main>
    </div>
  );
};

export default App;
