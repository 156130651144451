import { Error } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AddDetailParticipantDialog,
  CountdownTimer,
  EventInfoFragment,
  FormTextField,
  RaceOrRacepackInfo,
  RacepacksFragments,
  TicketSummaryDialog,
} from '..';
import { LoadingButton } from '@mui/lab';
import api from '../../services/apiClient';
import { useGetEventInformationQuery } from '../../services/iracedb';
import { read } from '../../utils/generateToken';
import { toCurrencyForm } from '../../utils/stringFormatter';
import CustomTabPanel, { a11lyProps } from '../CustomTabPanel/CustomTabPanel';
import classes from './style';
import prodigiLogo from '../../assets/Color horizontal.png';

const EventInformation = () => {
  // console.log('Event Information');
  //#region variable, state, initial value
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTab = useMediaQuery('(max-width:1000px)');

  const { id } = useParams();

  const { data, isFetching, error } = useGetEventInformationQuery(read(id));
  // console.log(data);
  // console.log(featuredRcp);

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenSummary, setIsOpenSummary] = useState(false);
  const [isBuyInsurance, setIsBuyInsurance] = useState(0);

  const [tabIdx, setTabIdx] = useState(0);
  const [dp, setDp] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [loadOrderAndGetSnapToken, setLoadOrderAndGetSnapToken] =
    useState(false);
  const [isFetchingCodeStatus, setIsFetchingCodeStatus] = useState(false);
  const [snapToken, setSnapToken] = useState('');
  const [chartUrl, setChartUrl] = useState();
  const [code, setCode] = useState({ value: '', errorMessage: '', data });
  //#endregion

  //#region useeffect
  useEffect(() => {
    if (snapToken === '') return;

    window.location.href = snapToken;
  }, [snapToken]);

  useEffect(() => {
    getSizeChartUrl();
  }, [data?.racepacks]);
  //#endregion

  //#region function
  const getSizeChartUrl = () => {
    if (!data?.racepacks.length === 0) return;

    const sizeChart = data?.racepacks?.find((rcp) =>
      rcp.name.includes('Chart')
    );

    setChartUrl(sizeChart?.image);
  };

  //#region handle code
  const handleCodeDataChange = (key, item) => {
    const updated = { ...code };

    updated[key] = item;

    setCode(updated);
  };
  //#endregion

  const handleJoinEvent = async () => {
    //return console.log('join event ', selectedCategory);
    try {
      if (code.value === '') return alert('Input Code.');
      setIsFetchingCodeStatus(true);

      const { data: couponData } = await api.get(
        `/events/offline/coupon/${data?.info?.eventId}/${code.value}/${selectedCategory?.categoryId}`
      );

      setIsFetchingCodeStatus(false);

      handleCodeDataChange('data', couponData);

      setIsOpenDialog(true);
    } catch (error) {
      setIsFetchingCodeStatus(false);
      alert(error.response.data);
    }
  };

  const handleOnChange = (event) => {
    const categoryFind = data?.categories?.find(
      (cat) => cat.categoryId.toString() === event.target.value.toString()
    );
    // console.log(categoryFind);
    setSelectedCategory(categoryFind);
  };

  const handleTabChange = (event, newValue) => {
    setTabIdx(newValue);
  };

  const handleGoToSummary = ({ email, team, participants, emergency }) => {
    const updated = { ...dp };
    updated.order = {
      email: { value: email.email.value },
      orderType: { value: 'web' },
      bill: { value: 0 },
    };

    updated.team = team;
    updated.participants = participants;
    updated.emergency = emergency;

    setDp(updated);
    setIsOpenDialog((prev) => !prev);
    setSnapToken('');

    setTimeout(() => {
      setIsOpenSummary((prev) => !prev);
    }, 200);
  };

  // const isSizeLimitValid = async () => {
  // if (data?.sizesInfo?.length === 0)
  //   return { valid: true, alertMessage: 'ok' };

  // const data = [...participants];

  // const sizes = [];

  // for (let index = 0; index < data.length; index++) {
  //   const element = data[index];

  //   const isFoundIdx = sizes.findIndex(
  //     (s) => s?.size === element?.size?.value
  //   );

  //   // console.log('is size limit valid, isFound ', isFoundIdx);

  //   if (isFoundIdx < 0) {
  //     sizes.push({ size: element.size.value, sizeCount: 1 });
  //   } else {
  //     sizes[isFoundIdx].sizeCount++;
  //   }
  // }
  // // console.log('is size limit valid ', sizes);
  // try {
  //   setCheckLimitLoad(true);
  //   const { data } = await api.post('/events/size/limit', {
  //     eventId,
  //     sizes,
  //   });

  //   setCheckLimitLoad(false);
  //   // console.log(result);
  //   if (data.isValid === false)
  //     return { valid: false, alertMessage: data.message };

  //   return { valid: true, alertMessage: 'ok' };
  // } catch (error) {
  //   setCheckLimitLoad(false);
  //   return { valid: false, alertMessage: error.message };
  // }
  // };

  const handleChoosePayment = async ({ bill, coupon }) => {
    // return console.log(data);

    // if (data?.info?.eventId === 45 && !coupon?.data?.coupon_id)
    //   return alert('You can continue after use coupon');

    if (parseInt(bill) === 0) return registerOfflineEvent({ bill, coupon });

    if (loadOrderAndGetSnapToken) return;
    if (snapToken !== '') return;

    setLoadOrderAndGetSnapToken(true);

    const params = { ...dp };
    params.order.bill.value = bill;

    const participantUpdated = params.participants.map((p) => {
      const localDateString = dayjs(p.birthDate.value).format('MM/DD/YYYY');
      return {
        ...p,
        birthDate: localDateString,
      };
    });
    params.participants = participantUpdated;

    // return console.log(
    //   JSON.stringify({
    //     event: data?.info,
    //     category: selectedCategory,
    //     coupon: {
    //       id: coupon?.data?.coupon_id,
    //       code: coupon?.data?.code,
    //     },
    //     ...params,
    //   })
    // );

    console.log('handle choosepayments');
    try {
      const result = await api.post('/events/offline/web2', {
        event: data?.info,
        category: selectedCategory,
        coupon: {
          id: coupon?.data?.coupon_id,
          code: coupon?.data?.code,
        },
        insurance: data?.info?.isInsuranceBundled > 0 ? 1 : isBuyInsurance,
        ...params,
      });

      //console.log('HERE ', result);
      // alert(`Check your offlane races table ${result.data}`);
      setSnapToken(result.data.url);
      // setTimeout(() => {
      //   setLoadOrderAndGetSnapToken(false);
      // }, 200);
    } catch (error) {
      setLoadOrderAndGetSnapToken(false);
      setSnapToken('');
      alert(JSON.stringify(error.response.data));
    }
  };

  const registerOfflineEvent = async ({ bill, coupon }) => {
    // console.log('register offline event!');

    // if (data?.info?.eventId === 45 && !coupon?.data?.coupon_id)
    //   return alert('You can continue after use coupon');

    const params = { ...dp };
    params.order.bill.value = bill;

    const participantUpdated = params.participants.map((p) => {
      const localDateString = dayjs(p.birthDate.value).format('MM/DD/YYYY');
      return {
        ...p,
        birthDate: localDateString,
      };
    });
    params.participants = participantUpdated;
    // console.log('register offline event free');
    // console.log(' coupon from TS ', coupon);
    // console.log('coupon code ', code);
    const cp = code?.data?.coupon_id ? code : coupon;
    // console.log(cp);
    // console.log({
    const regParams = {
      event: data?.info,
      category: selectedCategory,
      coupon: {
        id: cp?.data?.coupon_id,
        code: cp?.data?.code,
      },
      ...params,
    };

    // return console.log(regParams);

    setLoadOrderAndGetSnapToken(true);

    try {
      await api.post('/events/offline/web2/free', regParams);

      //console.log('HERE ', result);
      // alert(`Check your offlane races table ${result.data}`);
      alert(`Registration Complete, E-ticket will be sent via email`);

      window.location = '/';
      setTimeout(() => {
        setLoadOrderAndGetSnapToken(false);
      }, 150);
    } catch (error) {
      setLoadOrderAndGetSnapToken(false);
      alert(JSON.stringify(error.response.data));
    }
  };

  const getRemainingSlotText = (item) => {
    if (!item) return '-';

    const limit = parseInt(item.priceLimit);
    // console.log(limit);
    const priceCount =
      parseInt(item.pricePending) + parseInt(item.priceSuccess);
    // console.log(priceCount);
    const resultCount = limit - priceCount;
    // console.log('result count ', resultCount);

    if (resultCount > 0) {
      const dividerNumber =
        parseInt(item.adultsLimit) +
        parseInt(item.kidsLimit) +
        parseInt(item.participantLimit);
      // console.log('divider number ', dividerNumber);

      return resultCount / dividerNumber;
    } else {
      const pending = parseInt(item.pricePending);
      if (pending > 0) return 'Full';
      return 'Sold Out!';
    }
  };

  const checkSlotLimit = (item) => {
    if (!item) return;

    const limit = parseInt(item.priceLimit);
    // console.log(limit);
    const priceCount =
      parseInt(item.pricePending) + parseInt(item.priceSuccess);
    // console.log(priceCount);
    const resultCount = limit - priceCount;
    // console.log('result count ', resultCount);

    if (resultCount > 0) {
      // console.log('divider number ', dividerNumber);

      return true;
    } else {
      const pending = parseInt(item.pricePending);
      if (pending > 0) alert('Slot Full, please try again later');
      else alert('Ticket Sold Out!');

      return false;
    }
  };

  const joinEvent = (item) => {
    const valid = checkSlotLimit(item);

    if (valid === false) return;

    setIsOpenDialog((prev) => !prev);
  };
  //#endregion

  //#region loading and error view
  if (isFetching)
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        marginTop={'2rem'}
      >
        <CircularProgress size={'4rem'} />
      </Box>
    );

  if (error)
    return (
      <Box
        display={'flex'}
        flexGrow={1}
        flex={1}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        sx={{ height: '500px' }}
      >
        <Error sx={{ fontSize: '64px', color: '#afafaf' }} />
        <Typography sx={{ color: '#afafaf' }} variant="h3">
          {error.error.split(':')[1].toUpperCase()}
        </Typography>
      </Box>
    );
  //#endregion

  return (
    <>
      <Grid
        container
        style={classes.containerSpaceAround}
        sx={{
          flexDirection: { xs: 'column', lg: 'row' },
          flexWrap: { xs: 'wrap', sm: 'inherit' },
        }}
      >
        {/* image */}
        <Grid item sm={12} lg={5} style={classes.bannerContainer}>
          <img
            src={data?.info?.banner}
            alt={data?.info?.name}
            style={
              isMobile
                ? classes.bannerMobile
                : isTab
                ? classes.bannerMedium
                : classes.banner
            }
          />
        </Grid>
        {/* event detail information container */}
        <Grid
          item
          container
          lg={7}
          sx={{
            marginTop: { xs: '0', sm: '2rem', lg: '0' },
            paddingInline: { xs: '2em', sm: '0' },
            paddingBottom: { xs: '' },
          }}
        >
          {/* event information detail */}
          <Grid item xs={12} sm={6}>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                marginTop: isMobile ? '10px' : '0',
              }}
            >
              {/* event info -- start */}
              <Typography variant={!isMobile ? 'h4' : 'h6'} fontWeight={'600'}>
                {data?.info?.name}
              </Typography>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={tabIdx}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Details" {...a11lyProps(0)} />
                    <Tab label="Racepacks" {...a11lyProps(1)} />
                    {data?.info?.containInsurance && (
                      <Tab label="Insurance" {...a11lyProps(2)} />
                    )}
                  </Tabs>
                </Box>
                <CustomTabPanel value={tabIdx} index={0}>
                  {/* event info -- end */}
                  <RaceOrRacepackInfo
                    isMobile={isMobile}
                    label={'Race Info'}
                    location={data?.info?.eventLocation}
                    start={data?.info?.startDate}
                    end={data?.info?.endDate}
                  />
                  {/* racepack info -- start */}
                  <RaceOrRacepackInfo
                    isMobile={isMobile}
                    label={'Racepack Collection Info'}
                    location={data?.info?.racepackCollection}
                    start={data?.info?.startRacepackCollectionDate}
                    end={data?.info?.endRacepackCollectionDate}
                    isRacepack
                  />

                  {/* description */}
                  <EventInfoFragment
                    isMobile={isMobile}
                    label={'Description'}
                    body={data?.info?.description}
                  />

                  <EventInfoFragment
                    isMobile={isMobile}
                    label={'Race Organizer'}
                    body={data?.info?.organizer || '-'}
                  />

                  <EventInfoFragment
                    isMobile={isMobile}
                    label={'Race Management'}
                    body={data?.info?.raceManagement || '-'}
                  />

                  <EventInfoFragment
                    isMobile={isMobile}
                    label={'Timing System'}
                    body={data?.info?.timingSystem || '-'}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tabIdx} index={1}>
                  <RacepacksFragments racepacks={data?.racepacks} />
                </CustomTabPanel>
                {data?.info?.containInsurance && (
                  <CustomTabPanel value={tabIdx} index={2}>
                    <Paper
                      style={{
                        maxHeight: '720px',
                        overflowY: 'scroll',
                        padding: '8px',
                      }}
                    >
                      <EventInfoFragment
                        isMobile={isMobile}
                        label={'Asuransi di dukung oleh:'}
                        body={
                          <img
                            src={prodigiLogo}
                            alt="prodigi"
                            style={{ width: '200px' }}
                          />
                        }
                      />
                      <EventInfoFragment
                        isMobile={isMobile}
                        label={'Nama Produk Asuransi'}
                        body={'Asuransi Kecelakaan Diri'}
                      />
                      <EventInfoFragment
                        isMobile={isMobile}
                        label={'Manfaat'}
                        body={
                          <div>
                            1. Meninggal dunia akibat Kecelakaan IDR 50.000.000{' '}
                            <br />
                            2. Biaya Ketidakmampuan (Cacat) IDR 50.000.000{' '}
                            <br />
                            3. Biaya Pengobatan (termasuk biaya pengobatan
                            alternatif - shinshe berlisensi) IDR 5.000.000
                          </div>
                        }
                      />
                      <EventInfoFragment
                        isMobile={isMobile}
                        label={'Jaminan Asuransi'}
                        body={
                          <div>
                            1. Meninggal Dunia Akibat Kecelakaan: Memberikan
                            santunan jika Tertanggung mengalami kecelakaan
                            selama masa pertanggungan asuransi yang menyebabkan
                            kematian. Kecelakaan yang dimaksud adalah semua
                            kejadian yang setara dengan kecelakaan itu sendiri.{' '}
                            <br />
                            2. Penggantian Biaya Ketidakmampuan Akibat
                            Kecelakaan saat Olahraga Memberikan santunan jika
                            Tertanggung Ketika mengalami cacat sebagai akibat
                            dari Kecelakaan saat melakukan aktivitas olahraga.{' '}
                            <br />
                            3. Penggantian Biaya Pengobatan Akibat Kecelakaan
                            saat Olahraga: Memberikan penggantian biaya
                            pengobatan jika Tertanggung mengalami kecelakaan
                            saat melakukan aktifitas olahraga selama masa
                            pertanggungan asuransi yang menyebabkan tertanggung
                            menjalani perawatan kesehatan di rumah sakit atau
                            klinik yang memiliki izin rawat inap. Kecelakaan
                            yang dimaksud adalah semua kejadian yang setara
                            dengan kecelakaan itu sendiri dan tidak harus
                            menyebabkan kematian atau cacat tetap. <br />
                            4. Penggantian Biaya Pengobatan Alternatif (Sinshe
                            Berlisensi): Memberikan penggantian Biaya Perawatan
                            atau Pengobatan Alternatif atas kuitansi yang
                            dikeluarkan oleh pengobatan alternatif (sinshe) yang
                            berizin sebesar batas manfaat yang tercantum dalam
                            polis akibat kecelakaan yang terjadi selama masa
                            pertanggungan. Ketentuan perawatan atau pengobatan
                            alternatif harus dilakukan pada hari yang sama
                            terjadinya kecelakaan yang dijamin di dalam polis
                            dan klaim perawatan atau pengobatan alternatif harus
                            disertai dengan foto luka badan luar yang dialami
                            Tertanggung.
                          </div>
                        }
                      />
                      <EventInfoFragment
                        isMobile={isMobile}
                        label={'Batas Pertanggungan'}
                        body={
                          <div>
                            Ketentuan besarnya penggantian maupun santunan
                            untuk:
                            <br />
                            1. Selain manfaat Kematian, semua manfaat lainnya
                            dapat diklaim lebih dari 1(satu) kali selama masa
                            pertanggungan dan selama batas santunan masih
                            tersedia. <br />
                            2. Besarnya penggantian risiko Kematian dan Cacat
                            Tetap (sesuai prosentase kecacatan) diberikan sesuai
                            plan yang diambil. <br />
                            3. Penggantian risiko biaya Pengobatan di Rumah
                            Sakit bersifat total sesuai dengan plan yang diambil
                            dan menunjukkan bukti-bukti pengobatan/perawatan
                            yang sah/asli atau dilegalisir bila yang asli
                            dipergunakan untuk pengajuan klaim lainnya.
                          </div>
                        }
                      />
                      <EventInfoFragment
                        isMobile={isMobile}
                        label={'Metode Klaim'}
                        body={'Santunan / Tebusan (Reimbursement).'}
                      />
                      <EventInfoFragment
                        isMobile={isMobile}
                        label={'Kriteria Kelayakan Calon Tertanggung'}
                        body={
                          <div>
                            1. Kewarganegaraan: Warga Negara Indonesia dan Warga
                            Negara Asing yang memiliki izin tinggal di
                            Indonesia.
                            <br />
                            2. Usia: 12 tahun – 70 tahun
                          </div>
                        }
                      />
                      <EventInfoFragment
                        isMobile={isMobile}
                        label={'Syarat & Ketentuan'}
                        body={
                          <div>
                            Pengecualian Umum: <br />
                            A. Perang (dengan atau tanpa adanya pernyataan
                            perang), pemogokan kerja atau larangan bekerja,
                            kerusuhan atau huru-hara, pemberontakan, perang
                            saudara, invasi atau kegiatan perang yang serupa;{' '}
                            <br />
                            B. Terorisme yang melibatkan penggunaan bahan
                            nuklir, biologis atau kimiawi; <br />
                            C. Keterlibatan Tertanggung dalam komando atau tugas
                            / pelatihan penjinak bom, tugas militer seperti
                            menjaga ketertiban sipil, permusuhan dan perjalanan
                            dengan pesawat militer atau kapal yang dikemudikan
                            di dalam air;
                            <br />
                            D. Radiasi atau kontaminasi radioaktif; <br />
                            E. Bencana alam;
                            <br />
                            F. Tertanggung secara sengaja menggunakan alkohol
                            atau obat-obatan, bunuh diri atau upaya bunuh diri
                            atau upaya melukai diri sendiri baik dalam keadaan
                            sadar atau tidak sadar; <br />
                            G. Keterlibatan Tertanggung dalam tindakan melanggar
                            hukum, tangan keadilan atau paparan disengaja untuk
                            bahaya yang tidak diperlukan, kecuali dalam upaya
                            untuk menyelamatkan manusia; <br />
                            H. Setiap cedera atau Patah Tulang berkelanjutan
                            akibat Tertanggung sedang melakukan atau mencoba
                            untuk melakukan suatu tindak kejahatan atau menolak
                            penangkapan oleh petugas penegak hukum; <br />
                            I. Penyakit mental dan gangguan mental lainnya serta
                            kelainan bawaan dari lahir; <br />
                            J. Kehamilan atau persalinan dan komplikasinya;{' '}
                            <br />
                            K. Keterlibatan Tertanggung dalam segala jenis
                            penerbangan selain sebagai seorang penumpang yang
                            membayar di dalam penerbangan terbang yang terjadwal
                            dengan rutin yang disediakan dan dioperasikan oleh
                            suatu perusahaan penerbangan komersial; <br />
                            L. Kecelakaan sebagai akibat melakukan, mencoba atau
                            memprovokasi serangan; atau
                            <br />
                            M. Kecelakaan yang terjadi saat Tertanggung
                            melakukan pekerjaan seharihari yang merupakan satu
                            atau beberapa dari daftar di bawah: <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. Di atas
                            ketinggian 15 meter;
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. Bekerja di
                            tambang bawah tanah; <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. Diperlukan
                            membawa senjata api atau peledak; <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d.Bekerja lepas
                            pantai pada perusahaan gas, minyak bumi atau
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bensin
                            <br />
                            N.Pengecualian lain sesuai pengecualian standar
                            PSAKDI 2016.
                          </div>
                        }
                      />
                      <EventInfoFragment
                        isMobile={isMobile}
                        label={'Penanganan Klaim'}
                        body={
                          <div>
                            1. Pelaporan Klaim: <br />
                            &nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Manfaat
                            Meninggal Dunia: Maks. 60 (enam-puluh) hari kalendar
                            sejak tanggal kejadian. <br />
                            &nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Selain Meninggal
                            Dunia: Maks. 30 (tiga-puluh) hari calendar tanggal
                            kejadian <br />
                            2. Pelengkapan Dokumen Klaim: <br />
                            &nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Manfaat
                            Meninggal Dunia: Maks. 30 (tiga-puluh) hari kalendar
                            sejak tanggal Pelaporan. <br />
                            &nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Selain Meninggal
                            Dunia: Maks. 14 (empat-belas) hari kalendar tanggal
                            Pelaporan. <br />
                            3. Penilaian Klaim & Akseptasi: <br />
                            &nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Manfaat
                            Meninggal Dunia: Maks. 5 (lima) hari kerja sejak
                            dokumen lengkap diterima. <br />
                            &nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Selain Meninggal
                            Dunia: Maks. 3 (tiga) hari kerja sejak dokumen
                            lengkap diterima. <br />
                            4. Pembayaran Manfaat Klaim: <br />
                            &nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Manfaat
                            Meninggal Dunia: Maks. 5 (lima) hari kerja sejak
                            dari tanggal Letter of Discharge (LoD) diterima dari
                            Tertanggung.
                            <br />
                            &nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Selain Meninggal
                            Dunia: Maks. 3 (tiga) hari kerja sejak dari tanggal
                            Letter of Discharge (LoD) diterima dari Tertanggung.
                          </div>
                        }
                      />
                      <EventInfoFragment
                        isMobile={isMobile}
                        label={'Cara Klaim'}
                        body={
                          <div>
                            1. Pelaporan Klaim dari Tertanggung dilakukan
                            melalui :
                            <p>Akses url: https://polis.prodiginow.com</p>
                            <p>Atau</p>
                            <p>
                              Menghubungi Claim Hotline : 150118 tekan 2 WA
                              Claim : +6281280489167 Jam Kerja: Senin - Jumat
                              (08.00 - 17.00 WIB) Email : claim@prodiginow.com
                            </p>
                            2. Semua dokumen klaim dalam format digital (photo),
                            termasuk LOD yang ditandatangani oleh Tertanggung
                          </div>
                        }
                      />
                    </Paper>
                  </CustomTabPanel>
                )}
              </Box>
            </div>
          </Grid>

          {/* choose category */}
          <Grid item xs={12} sm={6}>
            <div
              style={{
                display: 'flex',
                marginLeft: isMobile ? '0' : '1rem',
                marginTop: isMobile ? '10px' : '0',
              }}
            >
              <Box
                display={'flex'}
                justifyContent={'flex-start'}
                marginBottom={'10px'}
                flexDirection={'column'}
                borderRadius={'14px'}
                padding={'10px 16px'}
                flex={1}
                sx={{
                  border: 3,
                  borderColor: 'grey.200',
                }}
              >
                {dayjs().unix() <
                  dayjs(data?.info?.startRegistrationDate).unix() && (
                  <CountdownTimer
                    openRegDate={data?.info?.startRegistrationDate}
                  />
                )}

                {dayjs().unix() >
                  dayjs(data?.info?.endRegistrationDate).unix() && (
                  <Typography variant="h4" color={'GrayText'}>
                    Registration Already Closed.
                  </Typography>
                )}

                {dayjs().unix() >=
                  dayjs(data?.info?.startRegistrationDate).unix() &&
                dayjs().unix() <=
                  dayjs(data?.info?.endRegistrationDate).unix() ? (
                  <FormControl>
                    <FormLabel id="choose-category-form">
                      Choose Your Category
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="choose-category-form"
                      value={selectedCategory?.categoryId}
                      onChange={handleOnChange}
                    >
                      {data?.categories?.map((category, idx) => (
                        <FormControlLabel
                          key={idx}
                          value={category?.categoryId}
                          control={<Radio />}
                          label={category?.name}
                        />
                      ))}
                    </RadioGroup>
                    {selectedCategory && (
                      <>
                        {data?.info?.isInternal === 0 && (
                          <Box display={'flex'} flexDirection={'column'}>
                            <Divider style={{ marginBottom: '4px' }} />
                            <Box display={'flex'} flexDirection={'row'}>
                              <Box
                                display={'flex'}
                                flexGrow={1}
                                flexDirection={'column'}
                              >
                                <Typography variant="body1" fontWeight={'600'}>
                                  Registration Fee
                                </Typography>
                                <Typography variant="body1">
                                  {selectedCategory?.registrationFee === '0'
                                    ? 'FREE'
                                    : `Rp
                                  ${toCurrencyForm(
                                    selectedCategory?.registrationFee
                                  )}`}
                                </Typography>
                              </Box>
                              <Box
                                display={'flex'}
                                flexGrow={1}
                                flexDirection={'column'}
                              >
                                <Typography variant="body1" fontWeight={'600'}>
                                  Price Group
                                </Typography>
                                <Typography variant="body1">
                                  {selectedCategory?.priceLabel}
                                </Typography>
                              </Box>
                              {data?.info?.eventId !== 63 && (
                                <Box
                                  display={'flex'}
                                  flexGrow={1}
                                  flexDirection={'column'}
                                >
                                  <Typography
                                    variant="body1"
                                    fontWeight={'600'}
                                  >
                                    Slot
                                  </Typography>
                                  <Typography variant="body1">
                                    {getRemainingSlotText(selectedCategory)}
                                  </Typography>
                                </Box>
                              )}
                            </Box>

                            <Button
                              variant="contained"
                              style={{ marginTop: '10px' }}
                              onClick={() => joinEvent(selectedCategory)}
                            >
                              Join Event!
                            </Button>
                          </Box>
                        )}
                        {data?.info?.isInternal > 0 && (
                          <Box display={'flex'} flexDirection={'column'}>
                            <Divider style={{ marginBottom: '4px' }} />
                            <Box
                              display={'flex'}
                              flexDirection={'row'}
                              marginBottom={'20px'}
                            >
                              <Box
                                display={'flex'}
                                flexGrow={1}
                                flexDirection={'column'}
                              >
                                <Typography variant="body1" fontWeight={'600'}>
                                  RegistrationFee
                                </Typography>
                                <Typography variant="body1">
                                  {selectedCategory?.registrationFee === '0'
                                    ? 'FREE'
                                    : `Rp
                                  ${toCurrencyForm(
                                    selectedCategory?.registrationFee
                                  )}`}
                                </Typography>
                              </Box>
                              <Box
                                display={'flex'}
                                flexGrow={1}
                                flexDirection={'column'}
                              >
                                <Typography variant="body1" fontWeight={'600'}>
                                  Price Group
                                </Typography>
                                <Typography variant="body1">
                                  {selectedCategory?.priceLabel}
                                </Typography>
                              </Box>
                              <Box
                                display={'flex'}
                                flexGrow={1}
                                flexDirection={'column'}
                              >
                                <Typography variant="body1" fontWeight={'600'}>
                                  Slot
                                </Typography>
                                <Typography variant="body1">
                                  {getRemainingSlotText(selectedCategory)}
                                </Typography>
                              </Box>
                            </Box>

                            <FormTextField
                              index={1}
                              i={0}
                              required
                              label={'Code'}
                              errorMessage={code.errorMessage}
                              value={code.value}
                              onChange={(e) =>
                                handleCodeDataChange('value', e.target.value)
                              }
                            />

                            <LoadingButton
                              variant="contained"
                              onClick={handleJoinEvent}
                              loading={isFetchingCodeStatus}
                            >
                              Join Event!
                            </LoadingButton>
                          </Box>
                        )}
                      </>
                    )}
                  </FormControl>
                ) : null}
              </Box>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <AddDetailParticipantDialog
        eventId={data?.info?.eventId}
        showDialog={isOpenDialog}
        closeDialog={() => setIsOpenDialog((prev) => !prev)}
        categoryId={selectedCategory?.categoryId}
        kidsLimit={selectedCategory?.kidsLimit}
        kidMaxAge={selectedCategory?.kidMaxAge}
        kidSizeRange={selectedCategory?.kidSizeIndex}
        adultsLimit={selectedCategory?.adultsLimit}
        adultMinAge={selectedCategory?.adultMinAge}
        adultMaxAge={selectedCategory?.adultMaxAge}
        maxAllowedDate={selectedCategory?.maxAllowedDate}
        adultSizeRange={selectedCategory?.adultSizeIndex}
        participantLimit={selectedCategory?.participantLimit}
        includeJersey={selectedCategory?.includeJersey}
        isAllSos={selectedCategory?.isAllSos}
        isGroup={selectedCategory?.isGroup}
        goToSummary={handleGoToSummary}
        sizeChartUrl={chartUrl}
        sizesInfo={data?.sizesInfo}
      />
      <TicketSummaryDialog
        showSummary={isOpenSummary}
        onClose={() => setIsOpenSummary((prev) => !prev)}
        event={data?.info}
        team={dp?.team}
        participants={dp?.participants}
        emergency={dp?.emergency}
        category={selectedCategory}
        internalCode={code?.data}
        order={dp?.order}
        onChoosePayment={handleChoosePayment}
        isLoading={loadOrderAndGetSnapToken}
      />
    </>
  );
};

export default EventInformation;
