/* eslint-disable react/jsx-no-comment-textnodes */
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment, forwardRef, useEffect, useState } from 'react';
import {
  toDateRange,
  toReadableDateString,
  // toReadableDateTimeString,
  toTimeRange,
} from '../../utils/dateFormatter';
import { useMediaQuery } from '@mui/material';
import { toCurrencyForm } from '../../utils/stringFormatter';
import { getDiscountValue } from '../../utils/numberFormatter';
import { LoadingButton } from '@mui/lab';
import FormTextField from '../FormTextField/FormTextField';
import api from '../../services/apiClient';
import prodigiLogo from '../../assets/Color horizontal.png';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TicketSummaryData = ({
  label,
  text,
  width = '100%',
  letterSpacing,
  multiline,
}) => (
  <TextField
    sx={{
      marginBottom: '10px',
      width: width,
      letterSpacing,
    }}
    defaultValue={text}
    label={label}
    size="small"
    multiline={multiline}
    InputProps={{
      readOnly: true,
    }}
  />
);

const TicketSummary = ({
  showSummary,
  onClose,
  event,
  team,
  participants,
  emergency,
  category,
  internalCode,
  order,
  onChoosePayment,
  isLoading = false,
}) => {
  const isMobile = useMediaQuery('(max-width: 899px)');
  //   console.log('ts-email ', order);
  //   console.log('ts-event ', event);
  //   console.log('ts-participant ', participants);
  //   console.log('ts-emergency ', emergency);
  //   console.log('ts-category ', category);

  //#region variable, state, load
  const [bill, setBill] = useState();
  const [coupon, setCoupon] = useState({ code: '', data: {} });
  const [isGetCouponData, setIsGetCouponData] = useState(false);
  //#endregion

  //#region use effect
  useEffect(() => {
    if (!showSummary) return;

    // console.log('internalCode ', internalCode);

    // if (!internalCode || !internalCode.code) {
    // console.log('internalCode null');
    setBill(category?.registrationFee);
    setCoupon({ code: '', data: {} });
    // } else {
    //   console.log('internalcode not null');
    //   setCoupon({ code: internalCode.code, data: internalCode });
    //   const totalBill = getTotalBill(
    //     category?.registrationFee,
    //     internalCode?.coupon_category,
    //     internalCode?.coupon_value
    //   );
    //   setBill(totalBill);
    // }
  }, [category, showSummary]);
  //#endregion

  //#region function
  const handleOnClose = () => {
    if (isLoading) return;

    onClose();
  };

  const handleDataChange = (e) => {
    const updated = { ...coupon };
    updated.code = e.target.value;
    setCoupon(updated);
  };

  const handleGetCouponData = async () => {
    // console.log('ts-event ', event);
    // console.log('ts-coupon ', coupon);
    // return console.log(coupon);
    if (coupon.code === '') return alert('Input Coupon Code.');

    try {
      setIsGetCouponData((prev) => !prev);
      const { data: couponData } = await api.get(
        `/events/offline/coupon/${event?.eventId}/${coupon.code}/${category?.categoryId}`
        // `/events/offline/coupon/${event?.eventId}/${coupon.code}`
      );

      const updated = { ...coupon };
      updated['data'] = couponData;
      // console.log('ticket summary get coupon data');
      // console.log(updated);
      setCoupon(updated);

      const totalBill = getTotalBill(
        category?.registrationFee,
        couponData?.coupon_category,
        couponData?.coupon_value
      );

      setBill(totalBill);

      setIsGetCouponData((prev) => !prev);
    } catch (error) {
      setIsGetCouponData((prev) => !prev);
      setCoupon({ code: '', data: {} });
      alert(error.response.data);
    }
  };

  const getTotalBill = (registrationFee, couponCategory, couponValue) => {
    let totalBill = parseInt(registrationFee);
    let value = parseInt(couponValue);

    let result = 0;
    switch (couponCategory) {
      case 'sale':
        result = parseInt(totalBill) - parseInt(value);
        break;
      case 'discount':
        result = totalBill - (totalBill * value) / 100;
        break;
      default:
        break;
    }

    // console.log(result);
    return result;
  };
  //#endregion

  return (
    <Fragment>
      <Dialog fullScreen open={showSummary} TransitionComponent={Transition}>
        <DialogContent sx={{ padding: { xs: '2rem 2rem', lg: '2rem 4rem' } }}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                {/* tab left */}
                <Grid item xs={12} md={3}>
                  {/* event info */}
                  <Typography variant="h5" gutterBottom>
                    Race Info
                  </Typography>
                  <Box
                    sx={{
                      m: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                    }}
                  >
                    <TicketSummaryData
                      text={event?.eventLocation}
                      label={'Location'}
                      multiline={true}
                    />
                    <TicketSummaryData
                      text={toDateRange(event?.startDate, event?.endDate)}
                      label={'Date'}
                      letterSpacing={1}
                    />
                    <TicketSummaryData
                      text={toTimeRange(event?.startDate, event?.endDate)}
                      label={'Time'}
                      letterSpacing={1}
                    />
                  </Box>
                  {/* racepack info */}
                  <Typography variant="h5" gutterBottom>
                    Racepack Info
                  </Typography>
                  <Box sx={{ m: 1, display: 'flex', flexDirection: 'column' }}>
                    <TicketSummaryData
                      text={event?.racepackCollection}
                      label={'Location'}
                      multiline={true}
                    />
                    <TicketSummaryData
                      text={toDateRange(
                        event?.startRacepackCollectionDate,
                        event?.endRacepackCollectionDate
                      )}
                      label={'Date'}
                      letterSpacing={1}
                    />
                    <TicketSummaryData
                      text={toTimeRange(
                        event?.startRacepackCollectionDate,
                        event?.endRacepackCollectionDate
                      )}
                      label={'Time'}
                      letterSpacing={1}
                    />
                  </Box>
                </Grid>

                {/* tab middle */}
                <Grid item xs={12} md={6}>
                  {/* email */}
                  <Typography variant="h5">Email</Typography>
                  <Box sx={{ m: 1 }}>
                    <TicketSummaryData text={order?.email?.value} />
                  </Box>
                  {/* team */}
                  {!!team?.name?.value && (
                    <>
                      <Typography variant="h5">Team</Typography>
                      <Box sx={{ m: 1 }}>
                        <TicketSummaryData text={team?.name?.value} />
                      </Box>
                    </>
                  )}
                  {/* participants */}
                  <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                    {participants?.length === 1
                      ? 'Participant'
                      : 'Participants'}
                  </Typography>
                  <Grid item container className={'scroll'}>
                    {participants?.map((pt, idx) => (
                      <Grid key={idx} item xs={12} lg={6}>
                        <DialogContentText variant="h5" align="center">
                          Runner {idx + 1}
                        </DialogContentText>
                        <Box
                          sx={{
                            m: 1,
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <TicketSummaryData
                            text={pt?.name?.value}
                            label={'Name'}
                          />
                          <TicketSummaryData
                            text={pt?.gender?.value}
                            label={'Gender'}
                          />
                          <TicketSummaryData
                            text={pt?.nationality?.value}
                            label={'Nationality'}
                          />
                          <TicketSummaryData
                            text={pt?.idType?.value}
                            label={'Id Type'}
                          />
                          <TicketSummaryData
                            text={pt?.idNumber?.value}
                            label={'Id Number'}
                          />
                          <TicketSummaryData
                            text={toReadableDateString(pt?.birthDate?.value)}
                            label={'Birhtdate (dd/mm/yyyy)'}
                            letterSpacing={1}
                          />
                          <TicketSummaryData
                            text={pt?.bloodType?.value}
                            label={'Blood Type'}
                          />
                          <TicketSummaryData
                            text={pt?.contact?.value}
                            label={'Phone Number'}
                          />
                          <TicketSummaryData
                            text={
                              pt?.orgCom?.value === '' ? '-' : pt?.orgCom?.value
                            }
                            label={'Organization / Community'}
                          />
                          {!!pt?.size?.value && (
                            <TicketSummaryData
                              text={pt?.size?.value}
                              label={'Size'}
                            />
                          )}
                          {!!pt?.emergencyPerson?.value && (
                            <TicketSummaryData
                              text={pt?.emergencyPerson?.value}
                              label={'Emergency Person'}
                            />
                          )}
                          {!!pt?.emergencyContact?.value && (
                            <TicketSummaryData
                              text={pt?.emergencyContact?.value}
                              label={'Emergency Phone Number'}
                            />
                          )}
                          {!!pt?.emergencyRelation?.value && (
                            <TicketSummaryData
                              text={pt?.emergencyRelation?.value}
                              label={'Emergency Relation'}
                            />
                          )}
                        </Box>
                      </Grid>
                    ))}

                    {/* emergency person */}
                    {!!emergency?.person?.value && (
                      <Box>
                        <Typography variant="h5">Emergency</Typography>
                        <Box sx={{ m: 1 }}>
                          <TicketSummaryData
                            text={emergency?.person?.value}
                            label={'Person'}
                          />
                          <TicketSummaryData
                            text={emergency?.contact?.value}
                            label={'Phone Number'}
                          />
                          <TicketSummaryData
                            text={emergency?.relation?.value}
                            label={'Relation'}
                          />
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>

                {/* tab right */}
                <Grid item xs={12} md={3}>
                  {
                    //#region category
                  }
                  <Typography variant="h5" gutterBottom>
                    Category
                  </Typography>
                  <Box sx={{ m: 1, display: 'flex', flexDirection: 'column' }}>
                    <TicketSummaryData text={category?.name} label={'Name'} />
                    <TicketSummaryData
                      text={`${participants?.length} ${
                        participants?.length === 1
                          ? 'participant'
                          : 'participants'
                      }`}
                      label={'Total Participant'}
                    />
                    <TicketSummaryData
                      text={category?.priceLabel}
                      label={'Price Group'}
                    />
                  </Box>
                  {
                    //#endregion
                  }

                  {
                    //#region insurance
                  }
                  {event?.isInsuranceBundled > 0 && (
                    <Box
                      display={'flex'}
                      justifyContent={'flex-start'}
                      marginBottom={'10px'}
                      flexDirection={'column'}
                      borderRadius={'14px'}
                      padding={'10px 16px'}
                      flex={1}
                      sx={{
                        border: 3,
                        borderColor: 'grey.200',
                      }}
                    >
                      <Box>
                        <Box display={'flex'} alignItems={'center'}>
                          <FormLabel
                            sx={{
                              fontSize: '20px',
                              flexGrow: 1,
                              fontWeight: '600',
                            }}
                          >
                            {'Insurance'}
                          </FormLabel>
                          <img
                            src={prodigiLogo}
                            alt="prodigi"
                            style={{
                              height: '24px',
                              width: '100px',
                              objectFit: 'contain',
                            }}
                          />
                        </Box>

                        <Divider style={{ margin: '10px 0' }} />
                        <Box display={'flex'}>
                          <FormLabel
                            sx={{
                              fontSize: '16px',
                              flexGrow: 1,
                              fontWeight: '600',
                            }}
                          >
                            {'Asuransi Kecelakaan Diri'}
                          </FormLabel>
                          <Typography variant="body1" fontWeight={'600'}>
                            {'Bundled'}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {
                    //#endregion
                  }

                  {
                    //#region coupons
                  }
                  <Box
                    display={'flex'}
                    justifyContent={'flex-start'}
                    marginBottom={'10px'}
                    flexDirection={'column'}
                    borderRadius={'14px'}
                    padding={'10px 16px'}
                    flex={1}
                    sx={{
                      border: 3,
                      borderColor: 'grey.200',
                    }}
                  >
                    <Box display={'flex'}>
                      <FormLabel
                        sx={{
                          fontSize: '16px',
                          flexGrow: 1,
                          fontWeight: '600',
                        }}
                      >
                        Registration Fee
                      </FormLabel>
                      <Typography variant="body1" fontWeight={'600'}>
                        {category?.registrationFee === '0'
                          ? 'FREE'
                          : `Rp
                                  ${toCurrencyForm(category?.registrationFee)}`}
                      </Typography>
                    </Box>
                    {coupon?.data?.coupon_category && (
                      <Box display={'flex'}>
                        <FormLabel
                          sx={{
                            fontSize: '16px',
                            flexGrow: 1,
                            fontWeight: '600',
                          }}
                        >
                          {coupon?.data?.coupon_category === 'discount'
                            ? `Disc(${coupon?.data?.coupon_value}%)`
                            : coupon?.data?.coupon_category}
                        </FormLabel>
                        <Typography variant="body1" fontWeight={'600'}>
                          {coupon?.data?.coupon_category === 'sale'
                            ? `Rp ${toCurrencyForm(coupon?.data?.coupon_value)}`
                            : `Rp ${toCurrencyForm(
                                getDiscountValue(
                                  category?.registrationFee,
                                  coupon?.data?.coupon_value
                                )
                              )}`}
                        </Typography>
                      </Box>
                    )}
                    <Divider
                      sx={{
                        marginTop: '4px',
                        marginBottom: '4px',
                        marginLeft: { xs: '78%', sm: '82%', md: '60%' },
                      }}
                    />
                    <Box display={'flex'}>
                      <FormLabel
                        sx={{
                          fontSize: '16px',
                          flexGrow: 1,
                          fontWeight: '600',
                        }}
                      >
                        Total Bill
                      </FormLabel>
                      <Typography variant="body1" fontWeight={'600'}>
                        {bill > 0 ? `Rp ${toCurrencyForm(bill)}` : 'FREE'}
                      </Typography>
                    </Box>
                    {event?.isCoupon > 0 && (
                      <Divider sx={{ margin: '4px 0' }} />
                    )}

                    {event?.isCoupon > 0 && (
                      <FormControl>
                        <FormLabel
                          id="coupon-form"
                          sx={{ fontSize: '18px', fontWeight: '600' }}
                        >
                          Coupon
                        </FormLabel>
                        <Box sx={{ marginTop: '10px' }}>
                          <FormTextField
                            index={0}
                            i={0}
                            label={'Coupon Code'}
                            value={coupon.code}
                            readOnly={!!coupon?.data?.code}
                            disabled={!!coupon?.data?.code}
                            onChange={handleDataChange}
                          />
                          <LoadingButton
                            variant="contained"
                            fullWidth
                            onClick={handleGetCouponData}
                            loading={isGetCouponData}
                            disabled={!!coupon?.data?.code}
                          >
                            Use Coupon
                          </LoadingButton>
                        </Box>
                      </FormControl>
                    )}
                  </Box>
                  {isMobile && (
                    <Box
                      sx={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {!isLoading && (
                        <Button autoFocus onClick={handleOnClose}>
                          Close
                        </Button>
                      )}
                      <LoadingButton
                        onClick={() => onChoosePayment({ bill, coupon })}
                        autoFocus
                        loading={isLoading}
                      >
                        {bill > 0 ? 'Choose Payment' : 'Register'}
                      </LoadingButton>
                    </Box>
                  )}
                  {
                    //#endregion
                  }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
        {!isMobile && (
          <DialogActions>
            {!isLoading && (
              <Button autoFocus onClick={handleOnClose}>
                Close
              </Button>
            )}
            <LoadingButton
              onClick={() => onChoosePayment({ bill, coupon })}
              autoFocus
              loading={isLoading}
            >
              {bill > 0 ? 'Choose Payment' : 'Register'}
            </LoadingButton>
          </DialogActions>
        )}
      </Dialog>
    </Fragment>
  );
};

export default TicketSummary;
